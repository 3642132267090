import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => {
  const position = {
    start: 'justify-content-start',
    center: 'justify-content-center',
    end: 'justify-content-end',
  }[fields.position || 'start']

  return (
    <section>
      <div className="container py-5">
        <div className={`row ${position}`}>
          <div className={`col-lg-${fields.width}`}>
            <ParseContent content={fields.description} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Text
